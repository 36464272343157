import React, {useState} from "react";
import AppLogo from 'assets/images/Logo_Food_Fridge@2x.png'
import ShareHelpImg from "assets/images/share_help_hand_top@2x.png";
import IconPlusAddFridge from 'assets/images/icon-plus-add-fridge@2x.png'
import {NavLink} from "react-router-dom";
import styles from "./AppHeader.module.scss";
import ShareHelp from "component/shareHelp/ShareHelp";
import {useSelector} from "react-redux";
import {selectUser} from "features/user/userReducer";
import Hamburger from 'hamburger-react'

const AppHeader = (): JSX.Element => {
  const user = useSelector(selectUser)
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false)

  const hideModal = () => setIsModalVisible(false)
  const showModal = () => setIsModalVisible(true)

  const [mobileMenuVisible, setMobileMenuVisible] = useState<boolean>(false)

  return (
    <header className={styles.AppHeader}>
      <div className={styles.MobileNav}>
        <NavLink
          to="/"
        >
          <img
            src={AppLogo}
            className={styles.AppLogo}
            alt="food fridge logo"
          />
        </NavLink>
        <Hamburger onToggle={e => setMobileMenuVisible(e)} toggled={mobileMenuVisible} />
        {mobileMenuVisible && <div className={`${styles.MobileNavLinks} animate__animated animate__fadeIn`}>
          {/*<NavLink*/}
          {/*    to="/recent"*/}
          {/*    className={styles.AppNavMobileLink}*/}
          {/*>Recent</NavLink>*/}
            <NavLink
                to="/"
                className={styles.AppNavMobileLink}
            >Map</NavLink>
            <NavLink
                to="/fridge-list"
                className={styles.AppNavMobileLink}
            >List</NavLink>
            <NavLink
                to="/about"
                className={styles.AppNavMobileLink}
            >About</NavLink>
            <NavLink
                to="#"
                onClick={showModal}
                className={styles.AppNavMobileLink}
            >Share and Help</NavLink>
          {
            user
              ? <NavLink
                to="/set-fridge"
                className={styles.AppNavMobileLink}
              >Add fridge</NavLink>
              : <></>
          }
        </div>
        }
      </div>

      <nav className={styles.AppNav}>
        <NavLink
          to="/"
        >
          <img
            src={AppLogo}
            className={styles.AppLogo}
            alt="food fridge logo"
          />
        </NavLink>
        <div className={styles.AppNavRegularLink}>
          {/*<NavLink*/}
          {/*  to="/recent"*/}
          {/*  className={styles.AppNavLink}*/}
          {/*>Recent</NavLink>*/}
          <NavLink
            to="/"
            className={styles.AppNavLink}
          >Map</NavLink>
          <NavLink
            to="/fridge-list"
            className={styles.AppNavLink}
          >List</NavLink>
          <NavLink
            to="/about"
            className={styles.AppNavLink}
          >About</NavLink>
        </div>
        <div className={styles.AppNavShareAndAdd}>
          <div className={styles.AppNavLink} onClick={showModal}>
            <img src={ShareHelpImg} alt="share and help" height={90} />
          </div>
          <NavLink
            to="/set-fridge"
            className={styles.AppNavLink}
          >
            {
              user
                ? <div className={styles.AddFridgeButton}>
                  <img src={IconPlusAddFridge} alt="share and help" />
                  <div><b>Add Fridge</b></div>
                </div>
                : <></>
            }
          </NavLink>
        </div>
      </nav>

      <ShareHelp show={isModalVisible} close={hideModal} />
    </header>
  )
}

export default AppHeader