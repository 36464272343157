import {FridgeStatus} from "./FridgeStatus";
import {DocumentData} from 'firebase/firestore'

interface InstagramData {
  photos: Array<string>
}

class Fridge {
  name: string
  address: string
  city: string
  state: string
  status: FridgeStatus
  instagram: string
  donationLink: string
  email: string
  zip: string | number
  notes: string
  location?: any
  imgSrc?: string

  firebaseId?: string
  created?: number
  lastModified?: number

  instagramData?: InstagramData

  constructor(
    name?: string,
    address?: string,
    city?: string,
    state?: string,
    status?: FridgeStatus,
    instagram?: string,
    donationLink?: string,
    email?: string,
    zip?: string | number,
    notes?: string,
    imgSrc?: string,
  ) {
    this.name = name || ''
    this.address = address || ''
    this.city = city || ''
    this.state = state || ''
    this.status = status || FridgeStatus.INACTIVE
    this.instagram = instagram || ''
    this.donationLink = donationLink || ''
    this.email = email || ''
    this.zip = zip || ''
    this.notes = notes || ''
    this.imgSrc = imgSrc || ''
    this.created = new Date().getTime()
    this.lastModified = new Date().getTime()
    this.instagramData = {
      photos: []
    }
  }

  assignMetadata(firebaseId: string) {
    this.firebaseId = firebaseId
  }

  assignInstagramData(instagramData: InstagramData) {
    this.instagramData = instagramData
  }

  toFirebase(location: { lat: number, lng: number } | undefined): DocumentData {
    return {
      name: this.name,
      address: this.address,
      city: this.city,
      state: this.state,
      status: this.status.toString(),
      instagram: this.instagram,
      donationLink: this.donationLink,
      email: this.email,
      zip: this.zip,
      notes: this.notes,
      location: location,
      imgSrc: this.imgSrc,
      lastModified: new Date().getTime(),
      created: this.created,
      instagramData: this.instagramData
    } as DocumentData
  }
}

export default Fridge