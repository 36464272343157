import { configureStore } from '@reduxjs/toolkit'
import fridgeReducer      from 'features/fridge/fridgeReducer'
import userReducer        from 'features/user/userReducer'

export const store = configureStore({
    reducer: {
        user: userReducer,
        fridge: fridgeReducer,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false
    }),
})
