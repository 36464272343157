import React from "react";
import TopFridgeList from "assets/images/top-fridge-list@2x.jpg";
import styles from './Page404.module.scss'

const Page404 = () => {
  return (
    <div className={styles.Page404}>
      <img
        src={TopFridgeList}
        className={styles.TopFridgeImg}
        alt="fridges top"
      />
      <div className={styles.Content}>
        <h1>Page could not be found.</h1>
      </div>
    </div>
  )
}

export default Page404