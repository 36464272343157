import React, {useEffect} from 'react';
import {Route, Routes} from "react-router-dom";
import FridgeMap from "views/FridgeMap";
import FridgeList from "views/FridgeList";
import About from "views/About";
import SetFridge from "views/SetFridge";
import Login from "views/Login";
import AppHeader from "component/header/AppHeader";
import Page404 from "views/Page404";
import {login, setAdminRole, setSocials} from "features/user/userReducer";
import {useDispatch} from 'react-redux'
import {toast, ToastContainer} from "react-toastify";
import {setAllFridges, setLoading} from "features/fridge/fridgeReducer";
import {auth, db} from "./firebase";
import {getAllFridges} from "./service/FridgeService";
import styles from './App.module.scss';
import 'react-toastify/dist/ReactToastify.css';
import {doc, getDoc} from "firebase/firestore/lite";
import InstagramAuth from "./views/IntagramAuth";
import config from 'googleConfig.json'
import axios from "axios";
import AppFooter from "./component/footer/AppFooter";

function App() {
  const dispatch = useDispatch()

  useEffect(() => {
    const getAdminRole = async (email: string): Promise<boolean> => {
      const docRef = doc(db, 'admins', email)
      const docSnap = await getDoc(docRef)
      return docSnap.exists()
    }

    const getLinkedSocials = async (uid: string) => {
      const payload = {
        uid: uid
      }
      const res = await axios.post(`${config.functionsUrl}/getLinkedSocials`, payload)
      return res?.data || null
    }

    auth.onAuthStateChanged(userAuth => {
      if (userAuth) {
        // noinspection TypeScriptValidateTypes
        dispatch(login({
          email: userAuth.email,
          uid: userAuth.uid,
        }))
        getLinkedSocials(userAuth.uid).then(res => dispatch(setSocials(res)))
      }
      getAdminRole(userAuth?.email || '').then((data) => dispatch(setAdminRole(data)))
    })

    // noinspection TypeScriptValidateTypes
    dispatch(setLoading(true))
    getAllFridges()
      .then(res => {
        // noinspection TypeScriptValidateTypes
        dispatch(setAllFridges(res))
      })
      .catch(err => {
        toast.error('Something went wrong')
      })
      .finally(() => {
        // noinspection TypeScriptValidateTypes
        dispatch(setLoading(false))
      })
  }, [dispatch])

  return (
    <div className={styles.App}>
      <div className={styles.AppHeaderWrapper}>
        <AppHeader />
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </div>
      <div className={styles.AppContent}>
        <Routes>
          <Route path="/" element={<FridgeMap />} />
          {/*<Route path="/recent" element={<Recent />} />*/}
          <Route path="/fridge-list" element={<FridgeList />} />
          <Route path="/about" element={<About />} />
          <Route path="/set-fridge" element={<SetFridge />} />
          <Route path="/login" element={<Login />} />
          <Route path="/instagram-auth" element={<InstagramAuth />} />
          <Route path="/*" element={<Page404 />} />
        </Routes>
      </div>
      <AppFooter />
    </div>
  );
}

export default App;
