import { createSlice } from '@reduxjs/toolkit';

export const fridgeSlice = createSlice({
    name: 'fridge',
    initialState: {
        fridges: [],
        isFetching: false
    },
    reducers: {
        setAllFridges: (state, action) => {
            state.fridges =  action.payload
        },
        setLoading: (state, action) => {
            state.isFetching = action.payload
        }
    },
});

export const { setAllFridges, setLoading } = fridgeSlice.actions;
export const selectFridges = state => state.fridge.fridges;
export const selectIsFetching = state => state.fridge.isFetching;

export default fridgeSlice.reducer;