enum FridgeStatus {
  INACTIVE = 'INACTIVE',
  ACTIVE = 'ACTIVE',
  // EMPTY = 'EMPTY',
  // HALF_FULL = 'HALF_FULL',
  // FULL = 'FULL',
  UNKNOWN = 'UNKNOWN',
}

const FridgeStatusMapper = {
  [FridgeStatus.INACTIVE]: {
    id: FridgeStatus.INACTIVE,
    name: 'Inactive',
  },
  [FridgeStatus.ACTIVE]: {
    id: FridgeStatus.ACTIVE,
    name: 'Active',
  },
  // [FridgeStatus.EMPTY]: {
  //   id: FridgeStatus.EMPTY,
  //   name: 'Empty',
  // },
  // [FridgeStatus.HALF_FULL]: {
  //   id: FridgeStatus.HALF_FULL,
  //   name: 'Half Full',
  // },
  // [FridgeStatus.FULL]: {
  //   id: FridgeStatus.FULL,
  //   name: 'Full',
  // },
  [FridgeStatus.UNKNOWN]: {
    id: FridgeStatus.UNKNOWN,
    name: 'Unknown',
  },
}

export {FridgeStatus, FridgeStatusMapper}