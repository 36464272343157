import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {selectSocials, selectUser} from "features/user/userReducer";
import {addDoc, collection, doc, updateDoc} from 'firebase/firestore/lite'
import {getDownloadURL, ref, StorageReference, uploadBytes} from 'firebase/storage'
import {FridgeStatus, FridgeStatusMapper} from "model/FridgeStatus";
import Fridge from "model/Fridge";
import Spinner from "component/spinner/Spinner";
import {useLocation} from "react-router-dom";
import {toast} from 'react-toastify';
import {convertAddressToLocation, getAllFridges, getFridgeDetails} from "service/FridgeService";
import {setAllFridges, setLoading} from "features/fridge/fridgeReducer";
import styles from './SetFridge.module.scss'
import {db, storage} from "../firebase";
import axios from 'axios'
import config from 'googleConfig.json'

const FRIDGES_BUCKET_NAME = 'fridges_images'

const SetFridge = (): JSX.Element => {
  const user = useSelector(selectUser)
  const socials = useSelector(selectSocials)
  const dispatch = useDispatch()
  const [fridge, setFridge] = useState<Fridge>(new Fridge())
  const [isUpdate, setIsUpdate] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isInstagramLoading, setIsInstagramLoading] = useState<boolean>(false)
  const [fridgeImage, setFridgeImage] = useState<any>(null)
  const fridgeImageRef = useRef(null)
  const [instagramPhotos, setInstagramPhotos] = useState<Array<string>>([])
  const search = useLocation().search;
  const fridgeId = new URLSearchParams(search).get('id')

  const [selectedInstagramPhotos, setSelectedInstagramPhotos] = useState<Array<string>>([])

  const formRef = useRef(null)
  const [fetchedFridge, setFetchedFridge] = useState<Fridge>()

  const fetchFridgeDetails = async () => {
    setIsLoading(true)
    await getFridgeDetails(fridgeId || '')
      .then(res => {
        if (res) {
          setFridge(Object.assign(new Fridge(), res))
          setFetchedFridge(Object.assign(new Fridge(), res))
          setSelectedInstagramPhotos(res.instagramData?.photos || [])
          setIsUpdate(true)
        } else {
          toast.error('Fridge not found')
          setIsUpdate(false)
        }
      })
      .catch(err => {
        console.error(err)
        if (fridgeId) toast.error('Something went wrong!')
      })
    setIsLoading(false)
  }

  useEffect(() => {
    if (!fridgeId) {
      setIsUpdate(false)
    } else {
      fetchFridgeDetails()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fridgeId])

  useEffect(() => {
    resetForm()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.email])

  const refreshFridges = () => {
    // noinspection TypeScriptValidateTypes
    dispatch(setLoading(true))
    getAllFridges()
      .then(res => {
        // noinspection TypeScriptValidateTypes
        dispatch(setAllFridges(res))
      })
      .catch(err => {
        toast.error('Something went wrong')
      })
      .finally(() => {
        // noinspection TypeScriptValidateTypes
        dispatch(setLoading(false))
      })
  }

  const onSubmit = async (evt: any) => {
    evt.preventDefault()
    setIsLoading(true)
    try {
      const location = await convertAddressToLocation([fridge.address, fridge.city, fridge.zip, fridge.state].filter(e => e !== '' && e).join(', '))
      if (fridgeImage) {
        const imgSrc = await handleUpload(fridge.name.replaceAll(/[^a-zA-Z0-9]/g, ''))
        updateFridge('imgSrc', imgSrc)
      }

      fridge.assignInstagramData({photos: selectedInstagramPhotos})

      const setCall = isUpdate
        // @ts-ignore
        ? updateDoc(doc(db, 'fridges', fridgeId), fridge.toFirebase(location))
        : addDoc(collection(db, 'fridges'), fridge.toFirebase(location))

      setCall
        .then(async () => {
          isUpdate && await fetchFridgeDetails()
          isUpdate
            ? toast.success('Fridge has been updated!')
            : toast.success('Fridge has been added!')
          await refreshFridges()
        })
        .catch(err => {
          console.error('err: ', err);
          err.code === 'permission-denied'
            ? toast.error('You do not have permission to update this fridge.')
            : toast.error('Something went wrong.')
        })
        .finally(() => setIsLoading(false))
    } catch (err: any) {
      toast.error('Something went wrong')
      console.error('err: ', err);
      setIsLoading(false)
    }
  }

  const resetForm = () => {
    setFridge(pv => {
      if (isUpdate) {
        return Object.assign(new Fridge(), fetchedFridge)
      } else {
        const updatedFridge = new Fridge()
        updatedFridge.email = user?.email
        return updatedFridge
      }
    })
    setFridgeImage(null)
    // @ts-ignore
    if (fridgeImageRef.current) fridgeImageRef.current.value = null
  }

  const updateFridge = (fieldName: string, val: string) => {
    setFridge((pv: Fridge) => {
      // @ts-ignore
      pv[fieldName] = val
      return pv
    })
  }

  const handleChangeImage = (evt: any) => {
    if (!evt.target.files[0]) {
      // @ts-ignore
      fridgeImageRef.current.value = null
      setFridgeImage(null)
      return
    }
    const fileSize = evt.target.files[0]?.size / 1024 / 1024
    const maxFileSize = 5
    if (fileSize > maxFileSize) {
      // @ts-ignore
      fridgeImageRef.current.value = null
      toast.error('Image size cannot be bigger than 5MB.')
    } else {
      setFridgeImage(evt.target.files[0])
    }
  }

  const handleUpload = async (fileName: string): Promise<string> => {
    if (!fridgeImage) return Promise.resolve('')
    const storageRef = ref(storage, `${FRIDGES_BUCKET_NAME}/${fileName}_${new Date().getTime()}.${fridgeImage?.name?.replace(/.*\./g, '') || ''}`)
    await uploadBytes(storageRef, fridgeImage)
    return getImgSrc(storageRef)
  }

  const getImgSrc = (fileRef: StorageReference): Promise<string> => getDownloadURL(fileRef)
    .then((url: string) => url)
    .catch((error: any) => {
      // A full list of error codes is available at
      // https://firebase.google.com/docs/storage/web/handle-errors
      switch (error.code) {
        case 'storage/object-not-found':
          break;
        case 'storage/unauthorized':
          // User doesn't have permission to access the object
          break;
        case 'storage/canceled':
          // User canceled the upload
          break;
        case 'storage/unknown':
          // Unknown error occurred, inspect the server response
          break;
      }
      console.error(error.code)
      toast.error('Image has not been uploaded. Try again in edit form.')
      return ''
    })

  const getInstagramPhotos = async () => {
    const payload = {
      uid: user.uid
    }
    const res = await axios.post(`${config.functionsUrl}/getInstagramPhotos`, payload)
    return res?.data || null
  }

  useEffect(() => {
    if (user) {
      setIsInstagramLoading(true)
      getInstagramPhotos()
        .then(res => {
          console.log('photos: ', res);
          setInstagramPhotos(res)
        })
        .catch(err => {
          console.error(err?.response?.data || 'Unexpected error.');
        })
        .finally(() => setIsInstagramLoading(false))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  const handleImageSelect = (imgSrc: string) => {
    setSelectedInstagramPhotos(pv => pv.includes(imgSrc) ? [...pv.filter(e => e !== imgSrc)] : [...pv, imgSrc])
  }

  return (
    <>
      <div className={`${styles.SetFridge} animate__animated animate__fadeIn`}>
        {
          isLoading
            ? <Spinner />
            : <form className={`${styles.FormContainer} animate__animated animate__fadeIn`} onSubmit={onSubmit} ref={formRef}>
              {
                isUpdate
                  ? <h2>Update fridge</h2>
                  : <h2>Add new fridge</h2>
              }

              <div className={styles.FormRow}>
                <label htmlFor="FridgeName" className={styles.FormRowLabel}>Fridge Name</label>
                <input
                  type="text"
                  className={styles.FormRowInput}
                  placeholder="e.g. Westside Community Fridge"
                  defaultValue={fridge.name}
                  onChange={(evt) => updateFridge('name', evt.target.value)}
                  required
                />
              </div>

              <div className={styles.FormRow}>
                <label htmlFor="Address" className={styles.FormRowLabel}>Location</label>
                <input
                  type="text"
                  className={styles.FormRowInput}
                  placeholder="Street Address"
                  defaultValue={fridge.address}
                  onChange={(evt) => updateFridge('address', evt.target.value)}
                  required
                />
              </div>

              <div className={styles.FormRow}>
                <label htmlFor="City" className={styles.FormRowLabel}>City</label>
                <input
                  type="select"
                  className={styles.FormRowInput}
                  placeholder="City"
                  defaultValue={fridge.city}
                  onChange={(evt) => updateFridge('city', evt.target.value)}
                  required
                />
              </div>

              <div className={styles.FormRow}>
                <label htmlFor="State" className={styles.FormRowLabel}>State</label>
                <input
                  type="text"
                  className={styles.FormRowInput}
                  placeholder="NY"
                  defaultValue={fridge.state}
                  onChange={(evt) => updateFridge('state', evt.target.value)}
                  required
                />
              </div>

              <div className={styles.FormRow}>
                <label htmlFor="zip" className={styles.FormRowLabel}>ZIP code</label>
                <input
                  type="text"
                  className={styles.FormRowInput}
                  placeholder="e.g. 10473"
                  defaultValue={fridge.zip}
                  onChange={(evt) => updateFridge('zip', evt.target.value)}
                />
              </div>

              <div className={styles.FormRow}>
                <label htmlFor="Location" className={styles.FormRowLabel}>Fridge status</label>
                <select
                  name="fridge status"
                  defaultValue={fridge.status}
                  onChange={(evt) => updateFridge('status', evt.target.value as FridgeStatus)}
                  className={styles.FormRowSelect}
                >
                  {
                    Object.values(FridgeStatusMapper).map(v =>
                      <option key={v.id} value={v.id}>{v.name}</option>
                    )
                  }
                </select>
              </div>

              <div className={styles.FormRow}>
                <label htmlFor="Instagram handle" className={styles.FormRowLabel}>Instagram</label>
                <input
                  type="text"
                  className={styles.FormRowInput}
                  placeholder="e.g. john.instagram"
                  defaultValue={fridge.instagram}
                  onChange={(evt) => updateFridge('instagram', evt.target.value)}
                />
              </div>

              <div className={styles.FormRow}>
                <label htmlFor="Donation Link" className={styles.FormRowLabel}>Donation Link</label>
                <input
                  type="text"
                  className={styles.FormRowInput}
                  placeholder="e.g. gofund.me/exampleName"
                  defaultValue={fridge.donationLink}
                  onChange={(evt) =>
                    updateFridge('donationLink', trimProtocol(evt.target.value))}
                />
              </div>

              <div className={styles.FormRow}>
                <label htmlFor="Email address" className={styles.FormRowLabel}>Email address (Volunteer contact)</label>
                <input
                  type="email"
                  className={styles.FormRowInput}
                  placeholder="Volunteer contact"
                  defaultValue={fridge.email}
                  onChange={(evt) => updateFridge('email', evt.target.value)}
                  required
                />
              </div>

              <div className={styles.FormRow}>
                <label htmlFor="Notes" className={styles.FormRowLabel}>Notes</label>
                <textarea
                  rows={5}
                  className={styles.FormRowTextarea}
                  placeholder="Type anything else people should know about this fridge!"
                  defaultValue={fridge.notes}
                  onChange={(evt) => updateFridge('notes', evt.target.value)}
                />
              </div>

              <div className={styles.ImageRow}>
                <label htmlFor="Notes" className={styles.FormRowLabel}>
                  {
                    isUpdate
                      ? 'Update Image'
                      : 'Image'
                  }
                </label>
                <div className={styles.ImageInput + ' ' + styles.FormRowInput}>
                  <div>
                    {fridgeImage?.name ? fridgeImage.name : 'Browse...'}
                  </div>
                  <input
                    type="file"
                    id="img"
                    name="img"
                    accept="image/*"
                    onChange={handleChangeImage}
                    ref={fridgeImageRef}
                  />
                </div>
              </div>

              {
                isBefore(socials?.instagram?.expirationDate || 0)
                  ? <>
                    (or) Select photos from Instagram
                    {
                      isInstagramLoading
                        ? <Spinner />
                        :
                        instagramPhotos.length > 0
                          ? <div className={styles.InstagramPhotos}>
                            {
                              instagramPhotos.map(imgSrc => <div className={styles.Photo}>
                                  <img
                                    src={imgSrc}
                                    className={selectedInstagramPhotos.includes(imgSrc) ? styles.active : ''}
                                    alt="instagram"
                                    key={imgSrc}
                                    onClick={() => handleImageSelect(imgSrc)}
                                  />
                                </div>
                              )
                            }
                          </div>
                          : <div>You have no instagram photos.</div>
                    }
                  </>
                  : <></>
              }

              <div className={styles.ButtonsRow}>
                <button className={styles.ResetButton} onClick={resetForm} type="reset">
                  <div><b>Reset</b></div>
                </button>
                <button className={styles.SubmitButton} type="submit">
                  <div><b>Submit</b></div>
                </button>
              </div>
            </form>
        }
      </div>
    </>
  )
}

const trimProtocol = (val: string) => val.replace(/(^\w+:|^)\/\//, '');

const isBefore = (timestamp: number) => (new Date().getTime()) < timestamp

export default SetFridge