import Fridge from "model/Fridge";
import {collection, deleteDoc, doc, getDoc, getDocs} from "firebase/firestore/lite";
import {db} from "../firebase";

const getAllFridges = async (): Promise<Array<Fridge>> => {
  const querySnapshot = await getDocs(collection(db, 'fridges'))
  const res: Array<Fridge> = []
  querySnapshot.forEach((doc) => {
    const docData = doc.data()
    const fridgeData =
      docData.location
        ? {
          ...docData,
          location: {
            lat: docData.location.lat + Math.random() / 2500,
            lng: docData.location.lng + Math.random() / 2500
          }
        }
        : docData
    const fridge = Object.assign(new Fridge(), fridgeData)
    fridge.assignMetadata(doc.id)
    res.push(fridge)
  })
  return res
}

const getFridgeDetails = async (fridgeId: string): Promise<Fridge | undefined> => {
  const docRef = doc(db, 'fridges', fridgeId)
  const docSnap = await getDoc(docRef)
  return docSnap.exists() ? docSnap.data() as Fridge : undefined
}

const deleteFridge = (fridgeId: string): Promise<void> => {
  return deleteDoc(doc(db, 'fridges', fridgeId))
}

const convertAddressToLocation = async (address: string): Promise<{ lat: number, lng: number } | undefined> => {
  const docRef = await doc(db, 'storage', 'mapquest')
  const docSnap = await getDoc(docRef)
  const apiKey = docSnap.exists() ? docSnap.data().apiKey : ''
  const mapQuestURL = 'https://www.mapquestapi.com/geocoding/v1/address'

  const payload = {
    location: address.trim(),
    options: {
      thumbMaps: false
    }
  }

  const response = await fetch(`${mapQuestURL}?key=${apiKey}`, {
    method: 'POST',
    body: JSON.stringify(payload)
  })

  const res = await response.clone().json()
  return res.results?.length > 0
    ? {
      lat: res.results[0].locations[0].latLng.lat,
      lng: res.results[0].locations[0].latLng.lng
    }
    : undefined
}

export {getAllFridges, getFridgeDetails, deleteFridge, convertAddressToLocation}