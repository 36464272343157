import TopFridgeList from "assets/images/top-about-us@2x.jpg";
import styles from './About.module.scss'
import ImageBronx from 'assets/images/image-about-bronx@2x.png';
import ImageBrooklyn from 'assets/images/image-about-brooklyn@2x.png';
import ImageManhattan from 'assets/images/image-about-manhattan@2x.png';
import ImageQueens from 'assets/images/image-about-queens@2x.png';
import ImageStatenIsland from 'assets/images/image-about-statenisland@2x.png';

const About = (): JSX.Element => {
  return (
    <div className={`${styles.About} animate__animated animate__fadeIn`}>
      <div className={styles.TopAboutImg}>
        <div />
        <div />
        <img
          src={TopFridgeList}
          alt="fridges top"
        />
        <div className={styles.AboutText}>
          <h1>About</h1>
        </div>
      </div>
      <div className={styles.Content}>
        <h2>Welcome to the world of New York City community fridges!</h2>
        <div className={styles.Description}>
          <p>
            Community fridges are open 24/7 to those who need food and those who want to donate. It is a take what you need, leave what you can approach. Most
            are on the sidewalk in front of a store or community center.
          </p>

          <p>
            If you donate food; fresh fruit and vegetables are what most people want. Then think healthy staples: loaves of bread, rice, pasta, canned goods
            and cereals. If its winter, instant oatmeal, or microwaveable meals. Pay attention to what each fridge may not welcome.
          </p>

          <p>
            Take a photo of the fridge and dry goods shelf after you donate or pick up food to post it through this QR code option so anyone can view the
            supply status in real time.
          </p>

          <p>
            If the fridge is using the electricity of a bodega, or grocery store, it’s a great show of support to buy an item or two from that sponsor.
            Sometimes they give discounts.
          </p>

          <p>
            If you want to donate to a fridge, they have Instagram handles or email accounts posted on the fridge itself or on our map links. Most use Venmo.
          </p>

          <p>
            <b>
              More than 1.5 million New Yorkers experience hunger now, including one in three children.
              This is the most direct way to support each other!
            </b>
          </p>
        </div>

        <div className={styles.Images}>
          <div>
            <img src={ImageBronx} alt="Bronx" />
            <b>BRONX</b>
          </div>
          <div>
            <img src={ImageBrooklyn} alt="Brooklyn" />
            <b>BROOKLYN</b>
          </div>
          <div>
            <img src={ImageManhattan} alt="Manhattan" />
            <b>MANHATTAN</b>
          </div>
          <div>
            <img src={ImageStatenIsland} alt="Staten Island" />
            <b>STATEN ISLAND</b>
          </div>
          <div>
            <img src={ImageQueens} alt="Queens" />
            <b>QUEENS</b>
          </div>


        </div>
      </div>
    </div>
  )
}

export default About