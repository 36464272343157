import Modal from "component/modal/Modal";
import React, {useState} from "react";
import styles from './ShareHelp.module.scss'
import Twitter from 'assets/images/icon-twitter@2x.png'
import Messenger from 'assets/images/icon-messenger@2x.png'
import WhatsApp from 'assets/images/icon-whatsapp@2x.png'
import CopyIcon from "assets/images/icon-copy@2x.png"
import QRCode from "assets/images/QRCode@2x.png"

interface ShareHelpProp {
  show: boolean,
  close: CallableFunction,
}

const ShareHelp = ({show, close}: ShareHelpProp) => {

  const [pageLink,] = useState<string>(window.origin)
  const [showCopiedText, setShowCopiedText] = useState<boolean>(false)

  const onTwitterClick = () => {
    window.open(`https://twitter.com/intent/tweet?text=${window.origin}`)
  }
  const onMessengerClick = () => {
    window.open(`fb-messenger://share/?link=${window.origin}&app_id=12345`)
  }
  const onWhatsAppClick = () => {
    window.open(`whatsapp://send?text=${window.origin}`)
  }

  const onCopyClick = (evt: any) => {
    evt.preventDefault()
    navigator.clipboard.writeText(pageLink).then(
      () => {
        setShowCopiedText(true)
        setTimeout(() => setShowCopiedText(false), 3000)
      },
      () => {/* clipboard write failed */
      }
    )
  }

  return (
    <>
      <Modal show={show} close={close}>
        <div className={styles.ShareHelp}>
          <h2>Share & Help</h2>
          <img src={QRCode} alt="share and help" className={styles.QRCode} />
          <div className={styles.Socials}>
            {/*<div className={styles.SocialsCol} onClick={onQrClick}>*/}
            {/*  <img src={QRCodeIcon} alt="qr code" />*/}
            {/*  <div>QRCode</div>*/}
            {/*</div>*/}
            <div className={styles.SocialsCol} onClick={onTwitterClick}>
              <img src={Twitter} alt="twitter" />
              <div>Twitter</div>
            </div>
            <div className={styles.SocialsCol} onClick={onMessengerClick}>
              <img src={Messenger} alt="messenger" />
              <div>Messenger</div>
            </div>
            <div className={styles.SocialsCol} onClick={onWhatsAppClick}>
              <img src={WhatsApp} alt="whats app" />
              <div>WhatsApp</div>
            </div>
          </div>
          <div className={styles.PageLinkContainer}>
            <h4>Page Link</h4>
            <div className={styles.PageLinkInput} onClick={onCopyClick}>
              <input type="text" disabled value={pageLink} />
              {showCopiedText && <div>Copied!</div>}
              <img src={CopyIcon} alt="copy" />
            </div>
            <h4>Posters</h4>
            <div className={styles.Posters}>
              <a href="poster_en.png" download>English</a>
              <a href="poster_es.png" download>Spanish</a>
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default ShareHelp