import Modal from "component/modal/Modal";
import React, {useEffect, useMemo, useState} from "react";
import styles from './PinDetailsModal.module.scss'
import LocationPin from 'assets/images/icon-location-right-box@2x.png'
import NeighborhoodPin from 'assets/images/icon-neighborhood-right-box@2x.png'
import InstagramIcon from 'assets/images/instagram-logo@2x.png'
import DonateIcon from 'assets/images/donate@2x.png'
import NoImage from 'assets/images/no-image.jpeg'
import {useSelector} from "react-redux";
import Fridge from "model/Fridge";
import {FridgeStatus} from "model/FridgeStatus";
import {selectIsAdmin, selectUser} from "features/user/userReducer";
import {NavLink, useNavigate} from "react-router-dom";
import {DateTime} from "luxon";
import Spinner from "../spinner/Spinner";
import GoogleMapsPin from 'assets/images/google-maps-pin.svg'

interface PinDetailsModalProps {
  fridgeData: Fridge,
  show: boolean,
  close: CallableFunction,
}

const PinDetailsModal = ({fridgeData, show, close}: PinDetailsModalProps) => {
  const user = useSelector(selectUser)
  const isAdmin = useSelector(selectIsAdmin)
  const navigation = useNavigate()
  const [isLoading, setIsLoading] = useState<boolean>(false)

  useEffect(() => {
    setIsLoading(true)
  }, [fridgeData?.imgSrc])

  const redirectToEdit = () => {
    navigation('/set-fridge?id=' + fridgeData.firebaseId)
  }

  const mailToUrl = `mailto:${fridgeData?.email || 'ssutherland@bhsusa.com'}
  ?subject=[Issue][foodfridge.org] - ${fridgeData?.name}
  &body=
----------------------------------------------------------------%0D
ID: ${fridgeData.firebaseId}%0D
Name: ${fridgeData.name}%0D
----------------------------------------------------------------%0D%0D
Issue description:
  `

  // const getStatusDots = (status: FridgeStatus) => {
  //   switch (status) {
  //     // case FridgeStatus.FULL:
  //     //   return (
  //     //     <>
  //     //       <div className={styles.FillStatusDot + ' ' + status} />
  //     //       <div className={styles.FillStatusDot + ' ' + status} />
  //     //       <div className={styles.FillStatusDot + ' ' + status} />
  //     //     </>
  //     //   );
  //     // case FridgeStatus.HALF_FULL:
  //     //   return (
  //     //     <>
  //     //       <div className={styles.FillStatusDot + ' ' + status} />
  //     //       <div className={styles.FillStatusDot + ' ' + status} />
  //     //       <div className={styles.FillStatusDot} />
  //     //     </>
  //     //   );
  //     // case FridgeStatus.EMPTY:
  //     //   return (
  //     //     <>
  //     //       <div className={styles.FillStatusDot + ' ' + status} />
  //     //       <div className={styles.FillStatusDot} />
  //     //       <div className={styles.FillStatusDot} />
  //     //     </>
  //     //   );
  //     case FridgeStatus.INACTIVE:
  //     case FridgeStatus.UNKNOWN:
  //       return (
  //         <>
  //           <div className={styles.FillStatusDot + ' ' + status} />
  //           <div className={styles.FillStatusDot + ' ' + status} />
  //           <div className={styles.FillStatusDot + ' ' + status} />
  //         </>
  //       );
  //     default:
  //       return (
  //         <>
  //           <div className={styles.FillStatusDot} />
  //           <div className={styles.FillStatusDot} />
  //           <div className={styles.FillStatusDot} />
  //         </>
  //       );
  //   }
  // }

  const isFridgeOwner = useMemo(() => user && user.email === fridgeData.email, [fridgeData, user])

  const setTheRoute = () => {
    const destination = `${fridgeData.address}+${fridgeData.zip}+${fridgeData.city}+${fridgeData.state}`
    const googleMapsUrl = `https://www.google.com/maps?saddr=My+Location&daddr=${destination}`
    window.open(googleMapsUrl, "_blank")
  }

  return (
    <>
      <Modal show={show} close={close}>

        <div className={styles.PinDetailsModal}>
          <h3> {fridgeData?.name || '-'} </h3>

          <div className={styles.Status}>
            <div className={styles.FridgeStatus + ' ' + fridgeData?.status}>
              <div>
                <b>
                  {fridgeData?.status === FridgeStatus.INACTIVE ? 'Inactive' : ''}
                  {fridgeData?.status === FridgeStatus.UNKNOWN ? 'Unknown' : ''}
                  {fridgeData?.status !== FridgeStatus.INACTIVE && fridgeData?.status !== FridgeStatus.UNKNOWN ? 'Active' : ''}
                </b>
              </div>
            </div>
            {/*{*/}
            {/*  fridgeData?.status !== FridgeStatus.INACTIVE*/}
            {/*    ? <div className={styles.FillStatus}>*/}
            {/*<div className={styles.FillStatusDots}>*/}
            {/*  {getStatusDots(fridgeData?.status || FridgeStatus.UNKNOWN)}*/}
            {/*</div>*/}
            {/*{fridgeData?.status === FridgeStatus.UNKNOWN ? '' : FridgeStatusMapper[fridgeData?.status]?.name || '-'}*/}
            {/*</div>*/}
            {/*: <></>*/}
            {/*}*/}
          </div>

          <div className={styles.AddressContainer}>
            <NavLink to={`/?fridge=${fridgeData.firebaseId}`}>
              <div>
                <img src={NeighborhoodPin} alt="neighborhood pin" />
                <div>
                  <div>City:</div>
                  <div><b>{fridgeData?.city || '-'}</b></div>
                </div>
              </div>
            </NavLink>
            <NavLink to={`/?fridge=${fridgeData.firebaseId}`}>
              <div>
                <img src={LocationPin} alt="location pin" />
                <div>
                  <div>Location:</div>
                  <div><b>{fridgeData?.address || '-'}</b></div>
                </div>
              </div>
            </NavLink>
          </div>

          {(fridgeData?.instagram || fridgeData?.donationLink) && <div className={styles.Socials}>

            {
              fridgeData?.instagram
                ? <div className={styles.Instagram}>
                  <a href={'https://www.instagram.com/' + fridgeData?.instagram} rel="noreferrer" target="_blank">
                    <img src={InstagramIcon} alt="instagram icon" />
                    <b>{fridgeData?.instagram || ''}</b>
                  </a>
                </div>
                : <></>
            }

            {
              fridgeData?.donationLink
                ? <div className={styles.Donation}>
                  <a href={'//' + fridgeData?.donationLink} rel="external noreferrer" target="_blank">
                    <img src={DonateIcon} alt="donate" />
                    <b>Donate</b>
                  </a>
                </div>
                : <></>
            }
          
          </div>
          }

          <div className={styles.FridgeDescription + ' ' + (fridgeData?.instagramData?.photos?.length || -1 > 0 ? '' : styles.SinglePhoto)}>
            <div className={styles.FridgePhotos}>
              {isLoading ? <Spinner /> : <></>}
              {
                (fridgeData?.instagramData?.photos?.length || -1 > 0) && !fridgeData?.imgSrc
                  ? <></>
                  : <img
                    src={fridgeData?.imgSrc || NoImage}
                    alt="fridge"
                    onLoad={() => setIsLoading(false)}
                    style={{display: isLoading ? 'none' : 'block'}}
                  />
              }
              {
                fridgeData?.instagramData?.photos.map(imgSrc => <img
                  onLoad={() => setIsLoading(false)}
                  key={imgSrc}
                  src={imgSrc}
                  alt="fridge"
                  style={{display: isLoading ? 'none' : 'block'}}
                />)
              }
            </div>
            <div style={{overflowWrap: 'anywhere'}}>
              <b>Notes:</b>
              <div>{urlify(fridgeData?.notes || 'There is no information about this fridge.')}</div>
            </div>
          </div>


          <button className={styles.SetRouteButton} onClick={setTheRoute}>
            <div>
              Set the route
            </div>
            <img src={GoogleMapsPin} alt="google maps pin" />
          </button>

          {
            isFridgeOwner || isAdmin
              ? <button className={styles.EditButton} onClick={redirectToEdit}>Edit</button>
              : <></>
          }

          {/*<button className={styles.CheckButton}>*/}
          {/*  Check*/}
          {/*</button>*/}

          <div className={styles.LastChecked}>
            Last checked: {toPrettyDate(fridgeData?.lastModified)}
          </div>


          {
            <div className={styles.ReportLink}>
              <a href={mailToUrl}>Report fridge</a>
            </div>
          }

        </div>
      </Modal>
    </>
  )
}

function urlify(text: string) {
  const urlRegex = /(https?:\/\/\S+)/g;
  return text.split(urlRegex)
    .map(part => {
      if (part.match(urlRegex)) {
        return <a href={part} target="_blank" rel="noreferrer">{part}</a>;
      }
      return part;
    });
}

const toPrettyDate = (timestamp?: number): string =>
  timestamp
    ? DateTime.fromMillis(timestamp).toLocaleString(DateTime.DATETIME_MED)
    : '-'

export default PinDetailsModal