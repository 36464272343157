import React, {useCallback, useEffect, useRef, useState} from 'react'
import {GoogleMap, Marker, MarkerClusterer, useJsApiLoader} from '@react-google-maps/api';
import PinFridge from 'assets/images/pin-fridge@2x.png'
import PinFridgeInactive from 'assets/images/pin-inactive@2x.png'
import PinFridgeUnknown from 'assets/images/pin-unknown@2x.png'
import {FridgeStatus} from "model/FridgeStatus";
import googleMapStyles from "./googleMapStyles";
import googleConfig from 'googleConfig.json'
import Fridge from "model/Fridge";

const containerStyle = {
  width: '100%',
  height: '100%'
};

const mapCenter = {
  lat: 40.730610,
  lng: -73.935242
};

const mapOptions = {
  styles: googleMapStyles,
  fullscreenControl: false,
  minZoom: 2.6,
  maxZoom: 25,
}

const markerClusterOptions = {
  // so you must have m1.png, m2.png, m3.png, m4.png, m5.png and m6.png in that folder
  // imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m',
  styles: [
    // {
    //   textColor: 'black',
    //   url: PinFridge,
    //   height: 5,
    //   width: 5
    // },
    // {
    //   textColor: 'yellow',
    //   url: 'path/to/mediumclusterimage.png',
    //   height: 50,
    //   width: 50
    // },
    // {
    //   textColor: 'red',
    //   url: 'path/to/largeclusterimage.png',
    //   height: 50,
    //   width: 50
    // }
  ],
  enableRetinaIcons: true
}

interface GoogleMapWrapperProps {
  handleMarkerClick: CallableFunction,
  fridges: Array<any>,
  focusFridge?: Fridge,
}

const GoogleMapWrapper = ({handleMarkerClick, fridges, focusFridge}: GoogleMapWrapperProps): JSX.Element => {
  const {isLoaded} = useJsApiLoader(googleConfig.maps)

  const [map, setMap] = useState<GoogleMap | null>(null)
  const [pinFridgeIcon, setPinFridgeIcon] = useState<any>({})

  const mapRef = useRef(null)

  useEffect(() => {
    if (isLoaded) {
      setPinFridgeIcon({
        [FridgeStatus.UNKNOWN]: {
          url: PinFridgeUnknown,
          size: new google.maps.Size(42, 44),
          scaledSize: new google.maps.Size(42, 44)
        },
        [FridgeStatus.ACTIVE]: {
          url: PinFridge,
          size: new google.maps.Size(42, 44),
          scaledSize: new google.maps.Size(42, 44)
        },
        // [FridgeStatus.FULL]: {
        //   url: PinFridgeGreen,
        //   size: new google.maps.Size(42, 44),
        //   scaledSize: new google.maps.Size(42, 44)
        // },
        // [FridgeStatus.HALF_FULL]: {
        //   url: PinFridgeYellow,
        //   size: new google.maps.Size(42, 44),
        //   scaledSize: new google.maps.Size(42, 44)
        // },
        // [FridgeStatus.EMPTY]: {
        //   url: PinFridgeRed,
        //   size: new google.maps.Size(42, 44),
        //   scaledSize: new google.maps.Size(42, 44)
        // },
        [FridgeStatus.INACTIVE]: {
          url: PinFridgeInactive,
          size: new google.maps.Size(42, 44),
          scaledSize: new google.maps.Size(42, 44)
        },
      })
    }
  }, [isLoaded])

  const onLoad = useCallback((map: any) => {
    const bounds = new google.maps.LatLngBounds()
    fridges.forEach(e => bounds.extend((e.location as google.maps.LatLngLiteral)))
    map.fitBounds(bounds)
    setMap(map)
  }, [fridges])

  useEffect(() => {
    if (focusFridge && map) {
      const bounds = new google.maps.LatLngBounds();
      bounds.extend(focusFridge.location as google.maps.LatLngLiteral)
      // @ts-ignore
      map.fitBounds(bounds)
    }
  }, [focusFridge, map])

  useEffect(() => {
    if (fridges?.length > 0 && map) {
      const bounds = new google.maps.LatLngBounds()
      fridges.forEach(e => bounds.extend((e.location as google.maps.LatLngLiteral)))
      // @ts-ignore
      map.fitBounds(bounds)
      setMap(map)
    }
  }, [fridges, map])

  const onUnmount = useCallback(function callback(map) {
    setMap(null)
  }, [])

  return isLoaded ? (
    <GoogleMap
      ref={mapRef}
      mapContainerStyle={containerStyle}
      center={mapCenter}
      zoom={10}
      onLoad={onLoad}
      onUnmount={onUnmount}
      options={mapOptions}
    >
      {fridges.length > 0
        ? <MarkerClusterer options={markerClusterOptions}>
          {(clusterer) =>
            fridges.map((fridge) => (
              <Marker
                key={createKey(fridge.location)}
                position={fridge.location as google.maps.LatLngLiteral}
                icon={pinFridgeIcon[fridge.status] || pinFridgeIcon[FridgeStatus.ACTIVE]}
                clusterer={clusterer}
                onClick={(evt) => handleMarkerClick(fridge)}
              />
            ))
          }
        </MarkerClusterer>
        : <></>
      }
    </GoogleMap>
  ) : <></>
}

const createKey = (location: any): string => (location.lat + location.lng).toString()

export default React.memo(GoogleMapWrapper)