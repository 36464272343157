import React, {useEffect, useState} from "react";
import axios from "axios";
import TopFridgeList from "assets/images/top-fridge-list@2x.jpg";
import {useLocation} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {selectUser, setSocials} from "features/user/userReducer";
import styles from './InstagramAuth.module.scss'
import {toast} from "react-toastify";
import Spinner from "../component/spinner/Spinner";
import config from 'googleConfig.json'

const InstagramAuth = () => {
  const dispatch = useDispatch()
  const search = useLocation().search;
  const instagramCode = new URLSearchParams(search).get('code')
  const user = useSelector(selectUser)
  const [isFetching, setIsFetching] = useState<boolean>(true)
  const [isSuccess, setIsSuccess] = useState<boolean>(false)

  const getLinkedSocials = async (uid: string) => {
    const payload = {
      uid: uid
    }
    const res = await axios.post(`${config.functionsUrl}/getLinkedSocials`, payload)
    return res?.data || null
  }

  useEffect(() => {
    setIsFetching(true)
    const getInstagramToken = async (code: string) => {
      const formData = new URLSearchParams();
      formData.append('code', code)
      formData.append('redirectUri', window.origin + '/instagram-auth')
      formData.append('uid', user.uid)
      const res = await axios.post(`${config.functionsUrl}/getInstagramToken`,
        formData,
        {
          headers: {'Content-Type': 'application/x-www-form-urlencoded'},
        }
      )
      return res?.data || null
    }

    setIsSuccess(false)
    if (instagramCode && user) {
      getInstagramToken(instagramCode)
        .then((res) => {
          console.log('res: ', res);
          toast.success('Your instagram has been connected to this account.')
          setIsSuccess(true)
          getLinkedSocials(user.uid).then(res => dispatch(setSocials(res)))
        })
        .catch((err) => {
          console.error(err.response?.data || 'Unexpected error');
          toast.error(err.response?.data || 'Something went wrong during connecting with instagram')
        })
        .finally(() => {
          setIsFetching(false)
        })
    } else if (!isFetching && !isSuccess) {
      toast.error('Invalid parameters. Try again later.')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  return (
    <div className={styles.IntagramAuth}>
      <img
        src={TopFridgeList}
        className={styles.TopFridgeImg}
        alt="fridges top"
      />
      <div className={styles.Content}>
        {
          isSuccess
            ? <h2>Your instagram has been connected. You can add fridge now.</h2>
            :
            <>
              {
                isFetching
                  ? <Spinner />
                  : <h2>Something went wrong during connection with your instagram.</h2>
              }
            </>
        }

      </div>
    </div>
  )
}

export default InstagramAuth