import { createSlice } from '@reduxjs/toolkit'

export const userSlice = createSlice({
    name: 'user',
    initialState: {
        user: null,
        isAdmin: null,
        socials: null,
    },
    reducers: {
        login: (state, action) => {
            state.user = action.payload
        },
        logout: state => {
            state.user = null
        },
        setAdminRole: (state, action) => {
            state.isAdmin = action.payload
        },
        setSocials: (state, action) => {
            state.socials = action.payload
        },
    },
})

export const { login, logout, setAdminRole, setSocials } = userSlice.actions
export const selectUser = state => state.user.user
export const selectIsAdmin = state => state.user.isAdmin
export const selectSocials = state => state.user.socials

export default userSlice.reducer