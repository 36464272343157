import TopFridgeList from 'assets/images/top-fridge-list@2x.jpg'
import IconPrint from 'assets/images/icon-print@2x.png'
import IconPDF from 'assets/images/icon-pdf@2x.png'
import FridgeListPin from 'assets/images/fridge-list-pin@2x.png'
import IconEdit from 'assets/images/icone_edit@2x.png'
import IconInfo from 'assets/images/icon_information@2x.png'
import IconRemove from 'assets/images/icon_trush@2x.png'
import React, {useEffect, useMemo, useState} from "react";
import PinDetailsModal from "component/pinModal/PinDetailsModal";
import {useDispatch, useSelector} from "react-redux";
import {selectIsAdmin, selectUser} from "features/user/userReducer";
import Fridge from "model/Fridge";
import {selectFridges, selectIsFetching, setAllFridges, setLoading} from "features/fridge/fridgeReducer";
import Spinner from "component/spinner/Spinner";
import {FridgeStatus, FridgeStatusMapper} from "model/FridgeStatus";
import {useNavigate} from "react-router-dom";
import {DateTime} from "luxon";
import Modal from "component/modal/Modal";
import {deleteFridge, getAllFridges} from "service/FridgeService";
import {toast} from "react-toastify";
import jsPDF from 'jspdf'
import styles from './FridgeList.module.scss'
import 'jspdf-autotable'

const FridgeList = (): JSX.Element => {
  const dispatch = useDispatch()
  const user = useSelector(selectUser)
  const isAdmin = useSelector(selectIsAdmin)
  const fridges = useSelector(selectFridges)
  const isFetching = useSelector(selectIsFetching)
  const [isDetailsModalVisible, setIsDetailsModalVisible] = useState<boolean>(false)
  const [detailsModalData, setDetailsModalData] = useState<Fridge>(new Fridge())
  const [isRemoveModalVisible, setIsRemoveModalVisible] = useState<boolean>(false)
  const [removeModalData, setRemoveModalData] = useState<Fridge>(new Fridge())
  const [isRemoving, setIsRemoving] = useState<boolean>(false)
  const navigation = useNavigate()

  const pdfFile = useMemo(() => {
    // noinspection JSPotentiallyInvalidConstructorUsage
    const doc = new jsPDF()
    doc.text("Food fridges - " + DateTime.now().toLocaleString(), 75, 20)
    // @ts-ignore
    doc.autoTable({
      head: [['Name', 'Address', 'City', 'State', 'ZIP', 'Status']],
      body: fridges.map((e: Fridge) => [e.name, e.address, e.city, e.state, e.zip, FridgeStatusMapper[e.status]?.name]),
      startY: 25,
    })
    return doc
  }, [fridges])

  const handlePrint = () => {
    window.open(pdfFile.output('bloburl'), '_blank');
  }

  const handlePdfDownload = () => {
    pdfFile.save(`food_fridges_list-${DateTime.now().toLocaleString()}.pdf`)
  }

  useEffect(() => {
    return () => {
      pdfFile.close()
    }
  }, [pdfFile])

  const redirectToEdit = (firebaseId: string) => {
    navigation('/set-fridge?id=' + firebaseId)
  }

  const handleInfo = (fridge: Fridge) => {
    setDetailsModalData(fridge)
    setIsDetailsModalVisible(true)
  }

  const handleRemove = (fridge: any) => {
    setRemoveModalData(fridge)
    setIsRemoveModalVisible(true)
  }

  const refreshFridges = () => {
    // noinspection TypeScriptValidateTypes
    dispatch(setLoading(true))
    getAllFridges()
      .then(res => {
        // noinspection TypeScriptValidateTypes
        dispatch(setAllFridges(res))
      })
      .catch(err => {
        toast.error('Something went wrong')
      })
      .finally(() => {
        // noinspection TypeScriptValidateTypes
        dispatch(setLoading(false))
      })
  }

  const submitRemove = () => {
    setIsRemoving(true)
    deleteFridge(removeModalData?.firebaseId || '')
      .then(() => toast.success('Fridge has been removed.'))
      .catch(() => toast.error('Something went wrong.'))
      .finally(() => {
        refreshFridges()
        setIsRemoving(false)
        setIsRemoveModalVisible(false)
        setRemoveModalData(new Fridge())
      })
  }

  const isFridgeOwner = (fridge: Fridge) => user && user.email === fridge.email

  return (
    <div className={`${styles.FridgeList} animate__animated animate__fadeIn`}>
      <div className={styles.TopFridgeList}>
        <div />
        <div />
        <img
          src={TopFridgeList}
          alt="fridges top"
        />
        <div className={styles.FridgeListText}>
          <h1>Fridge list</h1>
        </div>
      </div>
      {
        isFetching
          ? <Spinner />
          : <div className={`${styles.Content} animate__animated animate__fadeIn`}>
            <div className={styles.TableMenu}>
              <div className={styles.TableMenuRow} onClick={handlePrint}>
                <img src={IconPrint} alt="print" />
                <div>Print</div>
              </div>
              <div className={styles.TableMenuRow} onClick={handlePdfDownload}>
                <img src={IconPDF} alt="pdf" />
                <div>Download PDF</div>
              </div>
            </div>
            <div className={styles.Table}>
              {fridges.length > 0
                ? <div className={`${styles.TableHeader} ${user ? 'LOGGED_IN' : ''}`}>
                  <div><b>Fridge name</b></div>
                  <div><b>Added</b></div>
                  <div><b>Status</b></div>
                  {user && <div><b>Options</b></div>}
                </div>
                : <></>
              }
              <div className={styles.TableBody}>
                {
                  fridges.length > 0
                    ? fridges.map((e: Fridge) =>
                      <div
                        className={`${styles.TableRow} ${user ? 'LOGGED_IN' : ''}`}
                        key={e.firebaseId}
                        onClick={() => user ? void (0) : handleInfo(e)}
                      >
                        <div>
                          <img src={FridgeListPin} alt="fridge pin" />
                          <div>{e.name}</div>
                        </div>
                        <div> {toPrettyDate(e?.created)} </div>
                        <div className={styles.TableRowStatus + ' ' + e.status.toString()}>
                          <div>
                            <b>{
                              e.status.toString() === FridgeStatus.INACTIVE
                                ? 'Inactive'
                                : (e.status.toString() === FridgeStatus.UNKNOWN
                                  ? 'Unknown'
                                  : 'Active')
                            }</b>
                          </div>
                        </div>
                        {
                          user && <div className={styles.TableRowOptions}>
                                <img src={IconInfo} alt="info" onClick={() => handleInfo(e)} />
                            {isFridgeOwner(e) || isAdmin ? <img src={IconEdit} alt="edit" onClick={() => redirectToEdit(e?.firebaseId || '')} /> : <></>}
                            {isFridgeOwner(e) || isAdmin ? <img src={IconRemove} alt="remove" onClick={() => handleRemove(e)} /> : <></>}
                            </div>
                        }
                      </div>
                    )
                    : <div>There are not fridges.</div>
                }
              </div>
            </div>
          </div>
      }

      {
        detailsModalData && < PinDetailsModal
              fridgeData={detailsModalData}
              show={isDetailsModalVisible}
              close={() => setIsDetailsModalVisible(false)}
          />
      }

      {
        removeModalData && <Modal show={isRemoveModalVisible} close={() => setIsRemoveModalVisible(false)}>
              <div className={styles.RemoveModal}>
                  <h3>
                      Are you sure you want to remove <code style={{color: 'red'}}>{removeModalData?.name || '-'}</code> ?
                  </h3>
                {
                  isRemoving
                    ? <Spinner />
                    : <button onClick={submitRemove}>Remove</button>
                }
              </div>
          </Modal>
      }
    </div>
  )
}

const toPrettyDate = (timestamp?: number): string =>
  timestamp
    ? DateTime.fromMillis(timestamp).toLocaleString()
    : '-'

export default FridgeList