import {createPortal} from "react-dom";
import {ReactNode} from "react";
import styles from "./Modal.module.scss"
import {Modal as ResponsiveModal} from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';

interface ModalProps {
  show: boolean,
  close: CallableFunction,
  children: ReactNode,
}

const Modal = ({
                 show,
                 close,
                 children
               }: ModalProps) => {
  const modalContent = <>
    <ResponsiveModal
      open={show}
      onClose={() => close()}
      center
      closeIcon={CloseIcon}
      classNames={{
        overlay: styles.ModalContainer,
        modal: styles.Modal,
      }}
    >
      <div className={styles.Modal} onClick={(e) => e.stopPropagation()}>
        <main className={styles.ModalContent}>
          {children}
        </main>
      </div>
    </ResponsiveModal>
  </>

  // @ts-ignore
  return createPortal(modalContent, document.getElementById("modalRoot"))
};

const CloseIcon = (
  <svg width="30px" height="30px" viewBox="0 0 30 30" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <title>Close</title>
    <defs />
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Asset_Elements" transform="translate(-1194.000000, -767.000000)" stroke="#BCC0D0">
        <g id="icon-close-modalbox" transform="translate(1195.000000, 768.000000)">
          <g id="Group">
            <circle id="Oval" cx="14" cy="14" r="14" />
            <path d="M10.636,23.364 L23.364,10.636" id="Shape" transform="translate(-2.7, -2.7)" />
            <path d="M23.364,23.364 L10.636,10.636" id="Shape" transform="translate(-2.7, -2.7)" />
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default Modal;