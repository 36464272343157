import GoogleMapWrapper from "component/googleMapWrapper/GoogleMapWrapper";
import React, {useEffect, useMemo, useState} from "react";
import PinDetailsModal from "component/pinModal/PinDetailsModal";
import Spinner from "component/spinner/Spinner";
import Fridge from "model/Fridge";
import styles from './FridgeMap.module.scss'
import {useSelector} from "react-redux";
import {selectFridges, selectIsFetching} from "features/fridge/fridgeReducer";
import {FridgeStatus} from "../model/FridgeStatus";
import IconArrow from 'assets/images/icon-arrow-small-location-button@2x.png'
import {toast} from "react-toastify";
import IconClose from 'assets/images/icon-close-modalbox@2x.png'
import {useLocation} from "react-router-dom";

const FridgeMap = (): JSX.Element => {
  const fridges = useSelector(selectFridges)
  const isFetching = useSelector(selectIsFetching)
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false)
  const [modalData, setModalData] = useState<Fridge>(new Fridge())
  const [activeOnly, setActiveOnly] = useState<boolean>(false)
  const [seekingNearest, setSeekingNearest] = useState<boolean>(false)
  const [focusFridge, setFocusFridge] = useState<Fridge>()
  const [searchNearestShown, setSearchNearestShown] = useState<boolean>(true)
  const location = useLocation()

  const hideModal = () => setIsModalVisible(false)
  const showModal = () => setIsModalVisible(true)

  const onMarkerClick = (evt: any) => {
    setModalData(evt)
    showModal()
  }

  const filteredFridges = useMemo(() => {
    return fridges.filter((e: Fridge) => e.location &&
      (activeOnly ?
          (e.status !== FridgeStatus.INACTIVE && e.status !== FridgeStatus.UNKNOWN)
          : true
      )
    )
  }, [fridges, activeOnly])

  const findNearest = () => {
    setSeekingNearest(true)
    setFocusFridge(undefined)
    const geo = navigator.geolocation
    geo.getCurrentPosition(
      (gp) => {
        const res = findClosestMarker(filteredFridges, gp.coords.latitude, gp.coords.longitude)
        setFocusFridge(res)
        setSeekingNearest(false)
        setSearchNearestShown(false)
        toast.success('The nearest fridge has been found. Click on heart pin icon to get details.')
      }, () => {
        toast.error('Enable location services in your browser to use this feature.')
        setSeekingNearest(false)
      })
  }

  useEffect(() => {
    if (fridges.length > 0) {
      const fridgeId = (new URLSearchParams(location.search)).get('fridge')
      const found = fridges.find((fridge: Fridge) => fridge?.firebaseId === fridgeId)
      if (found) setFocusFridge(found)
    }
  }, [location.search, fridges])

  return (
    <div className={`${styles.FridgeMap} animate__animated animate__fadeIn`}>
      <div className={styles.Map}>
        {
          fridges && !isFetching
            ? <>
              {searchNearestShown && <div className={`${styles.SearchNearest} animate__animated animate__fadeIn`}>
                  <div onClick={() => setSearchNearestShown(false)} className={styles.SearchNearestMinimize}>
                      <img src={IconClose} alt="close" width={25} />
                  </div>
                  <div className={styles.SearchTitle}>Search</div>
                  <div className={styles.SearchSubTitle}>{filteredFridges.length} fridges found</div>
                  <div className={styles.SearchFilter}>
                      <div className={styles.SearchFilterButton + (!activeOnly ? ' active' : '')} onClick={() => setActiveOnly(false)}>All</div>
                      <div className={styles.SearchFilterButton + (activeOnly ? ' active' : '')} onClick={() => setActiveOnly(true)}>Active</div>
                  </div>
                  <div className={styles.SearchButton} onClick={findNearest}>
                      <div>
                          <img src={IconArrow} alt="location" />
                      </div>
                    {
                      seekingNearest
                        ? "Searching..."
                        : "Search near me"
                    }
                  </div>
              </div>
              }
              <div className={styles.ToggleSearchNearest} onClick={() => setSearchNearestShown(pv => !pv)}>
                {(searchNearestShown ? 'Hide' : 'Show') + ' search'}
              </div>
              <GoogleMapWrapper
                handleMarkerClick={onMarkerClick}
                fridges={filteredFridges}
                focusFridge={focusFridge}
              />
            </>
            : <Spinner />
        }
      </div>

      {
        modalData && <PinDetailsModal
              fridgeData={modalData}
              show={isModalVisible}
              close={hideModal}
          />
      }

    </div>
  )
}

const rad = (x: number) => x * Math.PI / 180

function findClosestMarker(markers: Array<any>, lat: number, lng: number) {
  const R = 6371; // radius of earth in km
  let distances: any = [];
  let closest = -1;
  markers
    .forEach((e: any, i: number) => {
      if (!e?.location?.lat || !e?.location?.lng) return
      const mLat = e.location.lat;
      const mLng = e.location.lng;
      const dLat = rad(mLat - lat);
      const dLong = rad(mLng - lng);
      const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(rad(lat)) * Math.cos(rad(lat)) * Math.sin(dLong / 2) * Math.sin(dLong / 2);
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      const d = R * c;
      distances[i] = d;
      if (closest === -1 || d < distances[closest]) {
        closest = i;
      }
    })
  return markers[closest]
}

export default FridgeMap