import React from "react";
import styles from "./AppFooter.module.scss";
import {NavLink} from "react-router-dom";

const AppFooter = (): JSX.Element => {
  return (
    <footer className={styles.AppFooter}>
      <div className={styles.AppFooterContent}>
        <div className={styles.Links}>
          <a href="https://twitter.com">Twitter</a>
          <a href="https://facebook.com">Facebook</a>
          <a href="https://instagram.com">Instagram</a>
          <NavLink to="/login">Volunteer panel</NavLink>
        </div>
        <div className={styles.CC}>
          © FoodFridge.org 2022
        </div>
      </div>
    </footer>
  )
}

export default AppFooter