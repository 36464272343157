import React, {useEffect, useState} from "react";
import {login, logout, selectSocials, selectUser, setSocials} from "features/user/userReducer";
import {useDispatch, useSelector} from "react-redux";
import {sendPasswordResetEmail, signInWithEmailAndPassword} from 'firebase/auth'
import Spinner from "component/spinner/Spinner";
import styles from './Login.module.scss'
import {auth} from "../firebase";
import {toast} from "react-toastify";
import axios from "axios";
import config from 'googleConfig.json'
import {NavLink} from "react-router-dom";
import EditIcon from 'assets/images/icone_edit@2x.png'
import InstagramIcon from 'assets/images/instagram-logo@2x.png'
import LogoutIcon from 'assets/images/logout-icon.png'

const Login = (): JSX.Element => {
  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const user = useSelector(selectUser)
  const socials = useSelector(selectSocials)
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const loginToApp = (evt: any) => {
    evt.preventDefault()
    setIsLoading(true)
    signInWithEmailAndPassword(auth, email, password)
      .then((userAuth) => {
        toast.success('Successful log in.')
        // noinspection TypeScriptValidateTypes
        dispatch(login({
          email: userAuth.user.email,
          uid: userAuth.user.uid,
        }))
        getLinkedSocials(userAuth.user.uid).then(res => dispatch(setSocials(res)))
        clearForm()
      })
      .catch(err => {
        if (err.message.startsWith('Firebase: Access to this account has been temporarily disabled due to many failed login attempts.')) {
          toast.error('Too many attempts. Try again later or reset your password.')
        } else if (err.message.startsWith('Firebase: Error (auth/wrong-password).') || err.message.startsWith('Firebase: Error (auth/user-not-found).')) {
          toast.error('Invalid credentials. Try again.')
        } else {
          toast.error('Something went wrong. Try again later.')
        }
      })
      .finally(() => setIsLoading(false))
  }

  const logoutApp = (evt: any) => {
    evt.preventDefault()
    setIsLoading(true)
    // noinspection TypeScriptValidateTypes
    dispatch(logout())
    auth.signOut()
      .then(res => {
        toast.success('You have been log out.')
      })
      .catch(err => {
        toast.error('Something went wrong. Try again later.')
      })
      .finally(() => setIsLoading(false))
  }

  const resetPassword = (evt: any) => {
    evt.preventDefault()
    sendPasswordResetEmail(auth, email)
      .then(res => {
        toast.success('New password has been sent to provided email.')
      })
      .catch(err => {
        toast.error('Provided email doesn\'t exists.')
      })
  }

  const clearForm = () => {
    setEmail('')
    setPassword('')
  }

  const connectWithInstagram = (evt: any) => {
    evt.preventDefault()
    const instagramAppId = '258097446462422'
    const instagramRedirectUri = window.origin + '/instagram-auth'
    const url = "https://api.instagram.com/oauth/authorize" +
      `?client_id=${instagramAppId}` +
      `&redirect_uri=${instagramRedirectUri}` +
      "&scope=user_profile,user_media" +
      "&response_type=code"
    window.location.href = url
  }

  const disconnectFromInstagram = async (evt: any) => {
    evt.preventDefault()
    const payload = {
      uid: user.uid
    }
    const res = await axios.post(`${config.functionsUrl}/deleteInstagramToken`, payload)
    if (res) {
      toast.success(res?.data)
      dispatch(setSocials(null))
    } else {
      toast.error('Something went wrong')
    }
  }

  const getLinkedSocials = async (uid: string) => {
    const payload = {
      uid: uid
    }
    const res = await axios.post(`${config.functionsUrl}/getLinkedSocials`, payload)
    return res?.data || null
  }

  useEffect(() => {
    if (user) getLinkedSocials(user.uid).then(res => dispatch(setSocials(res)))
  }, [dispatch, user])

  return (
    <div className={`${styles.Login} animate__animated animate__fadeIn`}>
      {
        isLoading
          ? <Spinner />
          : <div className={`${styles.Content} animate__animated animate__fadeIn`}>
            {
              user
                ? <>
                  <h2>Welcome to FoodFridge.org admin panel</h2>
                  <h3>You are logged in as <b>{user.email}</b>.</h3>
                  <form className={styles.Form}>
                    {
                      isBefore(socials?.instagram?.expirationDate || 0)
                        ? <button onClick={disconnectFromInstagram} className={styles.InstagramDisconnectButton}>
                          <img src={InstagramIcon} alt="instagram" />
                          <div>Disconnect from Instagram</div>
                        </button>
                        : <button onClick={connectWithInstagram} className={styles.InstagramConnectButton}>
                          <img src={InstagramIcon} alt="instagram" />
                          <div>Connect with Instagram</div>
                        </button>
                    }

                    <NavLink to="/fridge-list" className={styles.LinkButton}>
                      <img src={EditIcon} alt="edit fridges" />
                      <div>Edit fridges</div>
                    </NavLink>
                    <button type="submit" className={styles.AuthButton} onClick={logoutApp}>
                      <img src={LogoutIcon} alt="edit fridges" />
                      <div>Logout</div>
                    </button>
                  </form>
                </>
                : <>
                  <h1>Login</h1>
                  <form className={styles.Form} onSubmit={loginToApp}>
                    <label htmlFor="username">Email</label>
                    <input type="text" onChange={evt => setEmail(evt.target.value)} />

                    <label htmlFor="Password">Password</label>
                    <input type="password" onChange={evt => setPassword(evt.target.value)} />

                    <button type="submit" className={styles.AuthButton}>Sign In</button>
                    <button onClick={resetPassword} className={styles.ForgotPasswordButton}>Forgot password?</button>
                  </form>
                </>
            }
          </div>
      }
    </div>
  )
}

const isBefore = (timestamp: number) => (new Date().getTime()) < timestamp

export default Login